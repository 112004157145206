<template>
  <div>
    <div class="content">
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">电价类别收费管理</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">

                <el-form-item label="用电类别：">
                  <el-input clearable v-model="formInline.name" placeholder="请输入用电类别" class="custom-input"></el-input>
                </el-form-item>

                <el-form-item>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView">
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
              <el-button :class="isStringInArray('btnNewElectricity') ? '' : 'btnShowAuthority'" type="primary" @click="replyDialog(0)" icon="el-icon-plus" class="custom-button">新建电价类别</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
            :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%"
            class="custom-table">
            <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="tableText">
                    {{ dataLoading ? '' : '暂无数据' }}
                  </div>
                </div>
              </div>
            </div>
            <el-table-column align="center" label="序号" type="index" width="100">
            </el-table-column>
            <el-table-column align="center" prop="name" label="用电类别">
            </el-table-column>
            <!-- <el-table-column align="center" prop="startTime" label="创建时间">
            </el-table-column> -->
            <el-table-column align="center"  label="尖" class="firstTitle">
              <el-table-column align="center" prop="tipStaTime" label="开始时间">
              </el-table-column>
              <el-table-column align="center" prop="tipEndTime" label="结束时间">
              </el-table-column>
              <el-table-column align="center" prop="tipPrice" label="电价(元)">
              </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="name" label="峰" class-name="firstTitle">
              <el-table-column align="center" prop="peakStaTime" label="开始时间">
              </el-table-column>
              <el-table-column align="center" prop="peakEndTime" label="结束时间">
              </el-table-column>
              <el-table-column align="center" prop="peakPrice" label="电价(元)">
              </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="name" label="平" class-name="firstTitle">
              <el-table-column align="center" prop="flatStaTime" label="开始时间">
              </el-table-column>
              <el-table-column align="center" prop="flatEndTime" label="结束时间">
              </el-table-column>
              <el-table-column align="center" prop="flatPrice" label="电价(元)">
              </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="name" label="谷" class-name="firstTitle">
              <el-table-column align="center" prop="valleyStaTime" label="开始时间">
              </el-table-column>
              <el-table-column align="center" prop="valleyEndTime" label="结束时间">
              </el-table-column>
              <el-table-column align="center" prop="valleyPrice" label="电价(元)">
              </el-table-column>
            </el-table-column>

            <el-table-column align="center" prop="data" fixed="right" label="操作" width="200">
              <template slot-scope="scope">
                <el-button :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'"
                  @click="replyDialog(1, scope.row)" type="text" >编辑</el-button>
                <span :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">|</span>
                <el-button 
                  @click="replyDialog(2, scope.row)" type="text">详情</el-button>
                <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'" >|</span>
                <el-button :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'"
                  @click="openViewDialog(scope.row)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
            layout="prev, pager, next, sizes" :prev-text="prevButtonText" :next-text="nextButtonText"
            prev-button-class="custom-prev-button" next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建意见弹窗 -->
    <el-dialog @close="closeSubmit" :title="type == 0 ? '添加' : type == 1 ? '修改':'详情'" :visible.sync="centerDialogVisible" width="800px">
      <div class="newCenter">
        <el-form :model="addForm" ref="addForm" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="类型" prop="name">
            <el-input clearable :readonly="type==2" class="customInput" placeholder="请输入用电类型" v-model.trim="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="尖" >
            <el-row>
              <el-col :span="8">
                <el-form-item prop="tipStaTime">
                  <el-time-picker :readonly="type==2"  popper-class="pickTime" value-format="HH:mm" v-model="addForm.tipStaTime" :picker-options="pickerOptions"
                  placeholder="选择开始时间"> 
                </el-time-picker> 
                </el-form-item>
              </el-col> 
              <el-col :span="8">
                <el-form-item prop="tipEndTime">
                <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.tipEndTime" :picker-options="pickerOptions"
                  placeholder="选择结束时间">
                </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item  prop="tipPrice">
                <el-input :readonly="type==2" clearable class="customInput" placeholder="请输入电价(元)" v-model.trim="addForm.tipPrice"></el-input>
              </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="峰" >
            <el-row>
              <el-col :span="8">
                <el-form-item prop="peakStaTime">
                  <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.peakStaTime" :picker-options="pickerOptions"
                  placeholder="选择开始时间"> 
                </el-time-picker> 
                </el-form-item>
              </el-col> 
              <el-col :span="8">
                <el-form-item prop="peakEndTime">
                <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.peakEndTime" :picker-options="pickerOptions"
                  placeholder="选择结束时间">
                </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="peakPrice">
                <el-input :readonly="type==2" clearable class="customInput" placeholder="请输入电价(元)" v-model.trim="addForm.peakPrice"></el-input>
              </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="平" >
            <el-row>
              <el-col :span="8">
                <el-form-item prop="flatStaTime">
                  <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.flatStaTime" :picker-options="pickerOptions"
                  placeholder="选择开始时间"> 
                </el-time-picker> 
                </el-form-item>
              </el-col> 
              <el-col :span="8">
                <el-form-item prop="flatEndTime">
                <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.flatEndTime" :picker-options="pickerOptions"
                  placeholder="选择结束时间">
                </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="flatPrice">
                <el-input :readonly="type==2" clearable class="customInput" placeholder="请输入电价(元)" v-model.trim="addForm.flatPrice"></el-input>
              </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="谷" >
            <el-row>
              <el-col :span="8">
                <el-form-item prop="valleyStaTime">
                  <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.valleyStaTime" :picker-options="pickerOptions"
                  placeholder="选择开始时间"> 
                </el-time-picker> 
                </el-form-item>
              </el-col> 
              <el-col :span="8">
                <el-form-item prop="valleyEndTime">
                <el-time-picker :readonly="type==2" popper-class="pickTime" value-format="HH:mm" v-model="addForm.valleyEndTime" :picker-options="pickerOptions"
                  placeholder="选择结束时间">
                </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="valleyPrice">
                <el-input :readonly="type==2" clearable class="customInput" placeholder="请输入电价(元)" v-model.trim="addForm.valleyPrice"></el-input>
              </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer" v-if="type  !== 2">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="submitForm('addForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog title="删除" :visible.sync="replyDialogVisible" width="20%">
      <div class="deleteCenter">
        确定删除该电价类别?
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="deleteBookDialog">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getBookList, addBook, updateBook, deleteBook } from '../../api/dailyWork.js'
import {  priceList,addPriceList,updatePriceList,deletePriceList} from "@/api/eios.js"
//客服电话
export default {
  name: 'staffAddressBook',

  data() {
    return {
      pickerOptions: {
        format: 'HH:mm'
      },
      formInline: {
        name: '',
      },
      tableData: [],
      centerDialogVisible: false,
      replyDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 40,
      textarea: '',
      dataLoading: false,

      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      type: 0,
      id: '',
      addForm: {
        name: '',
        tipStaTime: '',
        tipEndTime: '',
        tipPrice: '',
        peakStaTime: '',
        peakEndTime: '',
        peakPrice: '',
        flatStaTime: '',
        flatEndTime: '',
        flatPrice: '',
        valleyStaTime: '',
        valleyEndTime: '',
        valleyPrice: '',


      },
      rules: {
        name: [
          { required: true, message: '请输入类型', trigger: 'blur' },
        ],
        tipStaTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        tipEndTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        tipPrice: [
          { required: true, message: '请输入电价', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '请输入大于等于0的数字', trigger: 'blur' },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
        peakStaTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        peakEndTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        peakPrice: [
          { required: true, message: '请输入电价', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '请输入大于等于0的数字', trigger: 'blur' },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
        flatStaTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        flatEndTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        flatPrice: [
          { required: true, message: '请输入电价', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '请输入大于等于0的数字', trigger: 'blur' },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
        valleyStaTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        valleyEndTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        valleyPrice: [
          { required: true, message: '请输入电价', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '请输入大于等于0的数字', trigger: 'blur' },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
       
      
      },
      authorityListBtn: []

    };
  },
  // components:{demo},
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit('selectChild', '电价类别收费管理')
    this.$store.commit("selectChildren", "");
    // this.getList()
  },

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    this.getList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')

  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },

  methods: {
   
    validateNumber(rule, value, callback) {
    const number = parseFloat(value);
    if (isNaN(number) || number <= 0) {
      callback(new Error('请输入大于0的数字'));
    } else {
      callback();
    }
  },
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    handleBeforePanelShow() {
      // 在时间选择面板弹出前执行
      const vm = this;
      setTimeout(() => {
        const timeSpinners = document.querySelectorAll('.el-time-spinner div.el-time-spinner__item:nth-child(3)');
        timeSpinners.forEach((spinner) => {
          spinner.style.display = 'none'; // 隐藏秒
        });
        vm.$refs.timePicker.handleConfirm(); // 确认选择，关闭时间选择面板
      }, 0);
    },
    //关闭新增/修改弹窗
    closeSubmit() {
      this.addForm = this.$options.data().addForm
      this.$refs.addForm.resetFields();
      console.log("1111111111");
    },
    //删除
    deleteBookDialog() {
      deletePriceList(this.id).then(res => {
        if (res.code == 200) {
          this.replyDialogVisible = false
          this.getList()
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });

        }
      })

    },


    //提交
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let func = this.type == 0 ? addPriceList(this.addForm) : updatePriceList(this.addForm)
          func.then(res => {
            if (res.code == 200) {
              this.centerDialogVisible = false
              this.getList()
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });

            }
          })

        } else {
          return false
        }
      });


    },
    // tree搜索

    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    //列表查询
    getList() {
      this.dataLoading = true
      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      priceList(obj).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.dataLoading = false
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },

    //新增/修改
    replyDialog(type, el) {
      if (el) {
        this.addForm = Object.assign({}, el)
      }
      this.type = type
      // console.log(el.data);
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
      this.getList()
    },

    //打开意见弹窗
    openViewDialog(el) {
      console.log(el);
      this.id = el.id
      this.replyDialogVisible = true
    },
    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
      this.getList()
    },
    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    }


  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 781px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  font-size: 16px;
  display: flex;
  overflow: auto;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #6180a8;
}

.customInput ::placeholder {
  color: #6180a8;
}

::v-deep .el-input ::placeholder {
  color: #6180a8;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  /* display: flex;
  align-items: center; */
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
  font-family: "Microsoft YaHei", Arial, sans-serif;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}



.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  padding-bottom: 15px;
}

.custom-select>>>.el-select-dropdown {
  background: #041C4C !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041C4C !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041C4C;
}

.el-select {
  width: 100%;
}

::v-deep .el-table th {
  background: #09224B !important;
  border-color: #06204A !important;
}
/* .firstTitle {
  font-size: 16px ;
  background: #000 !important;
} */
</style>

